// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-index-js": () => import("./../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-default-page-js": () => import("./../src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-form-page-js": () => import("./../src/templates/FormPage.js" /* webpackChunkName: "component---src-templates-form-page-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-product-page-js": () => import("./../src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-single-post-js": () => import("./../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

